import React from "react";
import { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import AniLink from "gatsby-plugin-transition-link/AniLink";

import { connect } from 'react-redux';
import { selectQuestion, saveEmail } from '../state/actions';

import '../styles/home.css';
import '../styles/global.css';
import '../styles/finish.css';

import Logo from '../assets/logo-goquiz.svg';
import audioHome from '../static/home.mp3';

const IndexPage = props => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    props.saveEmail('');
  }, []);

  useEffect(() => {
    if(props.email === '' || !validEmail(props.email)) {
      setActive(false);
    } else {
      setActive(true);
    }
  });

  const changeEmail = (e) => {
    validEmail(e.target.value);
    props.saveEmail(e.target.value);
  }

  const validEmail = (email) => {
    const regex = /^[a-z][_a-z0-9\-.]+@[a-z0-9]+\.[a-z]+(\.[a-z]+)?$/i;

    const result = regex.test(email);
    return result;
  }

  return (
    <div className="container">
      <Helmet>
        <title>
        GO Quiz - Go.K One Step Ahead
        </title>
      </Helmet>
      <audio autoplay id="audio_home">
        <source src={audioHome} type="audio/mpeg" />
      </audio>
      <div className="content">
        <div>
          <Logo />
        </div>
        <div className="base-text">
          <h1 className="title">Descubra<br /><span className="desc">o nível de maturidade da sua empresa!</span></h1>
        </div>
        <div className="base-email">
          <input
            name="email"
            type="email"
            placeholder="Digite seu email"
            className="input-email"
            required
            onChange={e => changeEmail(e)}
            value={props.email}
          />
        </div>
        { active ?
          <AniLink paintDrip to="/one" duration={1} direction="left" hex="#3ED206">
            <button
              className="btn-body btn-primary btn-top"
              onClick={() => props.selectQuestion([])}
            >
              Iniciar
            </button>
          </AniLink>
        :
          <button
            className="btn-body btn-disabled btn-top"
            onClick={() => alert('Preencha seu email!')}
          >
            Iniciar
          </button>
        }
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  questions: state.response,
  email: state.email
});

const mapStateToDispatch = {
  selectQuestion,
  saveEmail
}

export default connect(
  mapStateToProps,
  mapStateToDispatch
)(IndexPage)
